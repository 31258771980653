import axios from "axios";
import { useState } from "react";
import moment from "moment-timezone";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function useApi() {
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchProducts, setSearchProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [cartTimeReset, setCartTimeReset] = useState(false);
    const [dontShowTimeExtendAlert, setDontShowTimeExtendAlert] = useState(
        false
    );
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({});
    const [customer, setCustomer] = useState({});
    const [customerRewardItem, setCustomerRewardItem] = useState();
    const [cartTime, setCartTime] = useState(0);
    const [cartProducts, setCartProducts] = useState({});
    const [similar, setSimilar] = useState([]);
    const [favorite, setFavorite] = useState({});
    const [categories, setCategories] = useState([]);
    const [availabilityFilters, setAvailabilityFilters] = useState([]);
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [brandFilters, setBrandFilters] = useState([]);
    const [phenotypes, setPhenotypes] = useState([]);
    const [phenotypeFilters, setPhenotypeFilters] = useState([]);
    const [tastes, setTastes] = useState([]);
    const [tasteFilters, setTasteFilters] = useState([]);
    const [terpines, setTerpines] = useState([]);
    const [effects, setEffects] = useState([]);
    const [effectFilters, setEffectFilters] = useState([]);
    const [terpineFilters, setTerpineFilters] = useState([]);
    const [subFilters, setSubFilters] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [delivery, setDelivery] = useState({});
    const [favoriteProducts, setFavoriteProducts] = useState({});
    const [newsletter, setNewsletter] = useState({});
    const [cartAmount, setCartAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [updating, setUpdating] = useState(false);
    const [showGamificationPopOver, setShowGamificationPopOver] = useState(
        false
    );

    const [rewards, setRewards] = useState(
        localStorage.getItem("rewards")
            ? JSON.parse(localStorage.getItem("rewards"))
            : []
    );
    const [rewardsThreshold, setRewardsThreshold] = useState(
        localStorage.getItem("rewardsThreshold")
            ? JSON.parse(localStorage.getItem("rewardsThreshold"))
            : null
    );
    const [userId, setUserId] = useState(
        localStorage.getItem("userId") ? localStorage.getItem("userId") : null
    );
    const [priceClass, setPriceClass] = useState(
        localStorage.getItem("priceClass")
            ? localStorage.getItem("priceClass")
            : null
    );
    const [priceid, setPriceid] = useState(
        localStorage.getItem("priceid") ? localStorage.getItem("priceid") : null
    );
    const [customerId, setCustomerId] = useState(
        localStorage.getItem("customerId")
            ? localStorage.getItem("customerId")
            : null
    );
    const [accessToken, setAccessToken] = useState(
        localStorage.getItem("accessToken")
            ? localStorage.getItem("accessToken")
            : null
    );
    const [isAdmin, setIsAdmin] = useState(
        localStorage.getItem("isAdmin")
            ? localStorage.getItem("isAdmin")
            : false
    );
    const [firstLogin, setFirstLogin] = useState(
        localStorage.getItem("firstLogin")
            ? JSON.parse(localStorage.getItem("firstLogin"))
            : false
    );
    const [childCustomers, setChildCustomers] = useState(
        localStorage.getItem("childCustomers")
            ? JSON.parse(localStorage.getItem("childCustomers"))
            : []
    );
    const [multipleCustomers, setMultipleCustomers] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const headers = accessToken
        ? {
            token: `Bearer ${accessToken}`,
            priceClass: priceClass ? priceClass : "",
            priceid: priceid ? priceid : null
        }
        : {};

    const convertIntoNumber = (cartSessionTime) => {
        const actualReserveTime = 60 * 30;
        const timeDiff = moment().diff(cartSessionTime, "seconds");
        return actualReserveTime - timeDiff > 0
            ? actualReserveTime - timeDiff
            : 0;
    };

    return {
        order,
        cart,
        orders,
        userId,
        isAdmin,
        cartAmount,
        cartTime,
        dontShowTimeExtendAlert,
        setDontShowTimeExtendAlert,
        accessToken,
        cartOpen,
        favorite,
        favoriteProducts,
        searchProducts,
        setSearchProducts,
        searchText,
        setSearchText,
        product,
        products,
        setProducts,
        cartProducts,
        updating,
        customer,
        setCustomerId,
        categories,
        availabilityFilters,
        categoryFilters,
        brandFilters,
        effectFilters,
        phenotypeFilters,
        tasteFilters,
        terpineFilters,
        phenotypes,
        tastes,
        deliveries,
        delivery,
        childCustomers,
        multipleCustomers,
        priceClass,
        terpines,
        effects,
        similar,
        customerId,
        newsletter,
        subFilters,
        firstLogin,
        setFirstLogin,
        setUpdating,
        setCartAmount,
        totalPrice,
        setTotalPrice,
        originalPrice,
        setOriginalPrice,
        setCartOpen,
        setAvailabilityFilters,
        setCategoryFilters,
        setBrandFilters,
        setEffectFilters,
        setPhenotypeFilters,
        setTasteFilters,
        setSubFilters,
        setTerpineFilters,
        setMultipleCustomers,
        rewardsThreshold,
        rewards,
        customerRewardItem,
        setCustomerRewardItem,
        setCart,
        setCartTime,
        setCartTimeReset,
        cartTimeReset,
        showGamificationPopOver,
        setShowGamificationPopOver,

        login(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/auth/login`, data)
                    .then((response) => {
                        resolve(response.data);
                        setUserId(response.data._id);
                        setAccessToken(response.data.accessToken);
                        setCustomerId(response.data.customerId);
                        if (response.data.childCustomers.length > 0) {
                            setCustomerId(
                                response.data.childCustomers[0].customerId
                            );
                        }
                        setIsAdmin(response.data.isAdmin);
                        setCustomer(response.data.customer);
                        setPriceClass(response.data.customer.PriceClassID);
                        setPriceid(
                            response.data.customer.RPMId
                                ? response.data.customer.RPMId
                                : ""
                        );
                        setFirstLogin(true);
                        setRewards(response.data.rewards);
                        setChildCustomers(response.data.childCustomers);
                        if (response.data.customerRewardsThreshold) {
                            const formattedData = {
                                threshold1:
                                    response.data.customerRewardsThreshold
                                        .Threshold1.value,
                                threshold2:
                                    response.data.customerRewardsThreshold
                                        .Threshold2.value,
                                threshold3:
                                    response.data.customerRewardsThreshold
                                        .Threshold3.value
                            };
                            setRewardsThreshold(formattedData);
                            localStorage.setItem(
                                "rewardsThreshold",
                                JSON.stringify(formattedData)
                            );
                        } else {
                            localStorage.setItem("rewardsThreshold", null);
                        }

                        localStorage.setItem("userId", response.data._id);
                        localStorage.setItem(
                            "accessToken",
                            response.data.accessToken
                        );
                        localStorage.setItem(
                            "customerId",
                            response.data.customerId
                        );
                        localStorage.setItem(
                            "rewards",
                            JSON.stringify(response.data.rewards)
                        );
                        if (response.data.childCustomers.length > 0) {
                            localStorage.setItem(
                                "customerId",
                                response.data.childCustomers[0].customerId
                            );
                        }
                        localStorage.setItem("isAdmin", response.data.isAdmin);
                        localStorage.setItem(
                            "priceClass",
                            response.data.customer.PriceClassID
                        );
                        localStorage.setItem(
                            "priceid",
                            response.data.customer.RPMId
                                ? response.data.customer.RPMId
                                : ""
                        );
                        localStorage.setItem("firstLogin", true);
                        localStorage.setItem(
                            "childCustomers",
                            JSON.stringify(response.data.childCustomers)
                        );
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        logout() {
            return new Promise((res) => {
                setUserId(null);
                setAccessToken(null);
                localStorage.removeItem("userId");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("isAdmin");
                localStorage.removeItem("priceClass");
                localStorage.removeItem("priceid");
                localStorage.removeItem("rewardsThreshold");
                localStorage.removeItem("countdownTime");
                localStorage.removeItem("customerId");
                localStorage.removeItem("isTileView");
                localStorage.removeItem("firstLogin");
                localStorage.removeItem("childCustomers");
                localStorage.removeItem("rewards");
                res();
            });
        },
        register(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/auth/register`, data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        forgot(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/auth/forgot`, data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        request(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/auth/request`, data)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProducts(
            availability,
            brands,
            categories,
            subCategories,
            tastes,
            terpines,
            phenotypes,
            effects,
            sort,
            pageNum,
            pageLimit
        ) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `${API_ENDPOINT}/collections?page=${pageNum}&size=${pageLimit}&brands=${encodeURIComponent(brands.toString())}&categories=${categories.toString()}&subcategories=${subCategories.toString()}&tastes=${tastes}&terpines=${terpines}&phenotypes=${phenotypes}&effects=${effects}&sort=${sort}&availability=${availability}`,
                        { headers }
                    )
                    .then((response) => {
                        resolve(response.data);
                        const actualProducts =
                            pageNum === 1
                                ? response.data.products
                                : [...products, ...response.data.products];
                        setProducts(actualProducts);
                        setCategories(response.data.categories);
                        setPhenotypes(response.data.phenotypes);
                        setTerpines(response.data.terpines);
                        setTastes(response.data.tastes);
                        setEffects(response.data.effects);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProductsCategories() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/productCategories`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setCategories(response.data.categories);
                        setPhenotypes(response.data.phenotypes);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProductsByAvailability(availability) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/${availability}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getNewRelease() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/new-release`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getFastMovers() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/fast-movers`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProductsByTitle(title) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/collections/title/${title}`, {
                        headers
                    })
                    .then((response) => {
                        resolve(response.data);
                        setSearchProducts(response.data.products);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProduct(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/products/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setProduct(response.data.product);
                        setSimilar(response.data.similarProducts);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getProductInventory(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/products/handle/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getCart(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/carts/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setCart(response.data.cart);
                        setCartProducts(response.data.cartProducts);
                        setCartTime(
                            convertIntoNumber(response.data.cart.SessionTime)
                        );
                    })
                    .catch((error) => {
                        // reject(error.response.data);
                        setCart({});
                        setCartProducts({});
                        setCartAmount(0);
                    });
            });
        },
        addCart(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/carts`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setCart(response.data.savedCart);
                        setCartProducts(response.data.cartProducts);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        updateCart(id, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${API_ENDPOINT}/carts/${id}`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setCart(response.data.updatedCart);
                        setCartProducts(response.data.cartProducts);
                        setCartTime(
                            convertIntoNumber(
                                response.data.updatedCart.SessionTime
                            )
                        );
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        removeCart(id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${API_ENDPOINT}/carts/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getFavorite(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/favorites/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setFavorite(response.data.favorite);
                        setFavoriteProducts(response.data.favoriteProducts);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                        setFavorite({});
                        setFavoriteProducts({});
                    });
            });
        },
        addFavorite(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/favorites`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setFavorite(response.data.savedFavorite);
                        setFavoriteProducts(response.data.favoriteProducts);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        updateFavorite(id, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${API_ENDPOINT}/favorites/${id}`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setFavorite(response.data.updatedFavorite);
                        setFavoriteProducts(response.data.favoriteProducts);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        addOrder(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/orders`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getOrders(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/orders/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setOrders(response.data.orders);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getOrder(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/orders/order/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setOrder(response.data.order);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getCustomer(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/customers/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setCustomer(response.data.customer);
                        setRewards(response.data.rewards);
                        // setPriceid(
                        //     response.data.customer.RPMId
                        //         ? response.data.customer.RPMId
                        //         : ""
                        // );
                        if (response.data.customerRewardsThreshold) {
                            const formattedData = {
                                threshold1:
                                    response.data.customerRewardsThreshold
                                        .Threshold1.value,
                                threshold2:
                                    response.data.customerRewardsThreshold
                                        .Threshold2.value,
                                threshold3:
                                    response.data.customerRewardsThreshold
                                        .Threshold3.value
                            };
                            setRewardsThreshold(formattedData);
                            localStorage.setItem(
                                "rewardsThreshold",
                                JSON.stringify(formattedData)
                            );
                            localStorage.setItem(
                                "rewards",
                                JSON.stringify(response.data.rewards)
                            );
                        } else {
                            setRewardsThreshold(null);
                            localStorage.setItem("rewardsThreshold", null);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getCustomerRewards() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/rewards?customerId=${customerId}`, {
                        headers
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getNewsletter() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/newsletter`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        updateNewsletter(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/newsletter`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setNewsletter(response.data.updatedNewsletter.banners);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getSpotLightProduct() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/spotlight`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        updateSpotLightProduct(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/spotlight `, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        getDeliveries() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/delivery`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setDeliveries(response.data.deliveries);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getDelivery(id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/delivery/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setDelivery(response.data.delivery);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        addDelivery(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_ENDPOINT}/delivery`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setDelivery(response.data.savedDelivery);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        rebuildCart(id) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${API_ENDPOINT}/carts/${id}/${customerId}/rebuildCart`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        getSimilarProducts(productId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/${productId}/similiarProducts`, {
                        headers
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        reviewOrder() {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${API_ENDPOINT}/orders/${customerId}/reviewOrder`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        extendCartTime() {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${API_ENDPOINT}/carts/${customerId}/addTime`, "", {
                        headers
                    })
                    .then((response) => {
                        resolve(response.data);
                        setCartTime(
                            convertIntoNumber(response.data.SessionTime)
                        );
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        updateDelivery(id, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${API_ENDPOINT}/delivery/${id}`, data, { headers })
                    .then((response) => {
                        resolve(response.data);
                        setDelivery(response.data.updatedDelivery);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        getCutoff() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_ENDPOINT}/delivery/cutoff/update`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        updateCutoff(data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${API_ENDPOINT}/delivery/cutoff/update`, data, {
                        headers
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },
        removeDelivery(id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${API_ENDPOINT}/delivery/${id}`, { headers })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        }
    };
}

// 2023-11-13T08:22:48.363Z
// 2023-11-13T06:43:38.188Z
